<div class="panel-modal modal-container-otp-confirm-number container-fluid">
	<div class="container-fluid">
		<div class="text-center col-12 mb-3">
			<h5 class="modal-title mb-3">
				<b>{{ "CREATE_USER.CONFIRM_OTP_NUMBER_MODAL_TITLE" | translate }}</b>
			</h5>
			<h1>{{formattedOtpPhone}}</h1>
		</div>
		<div class="row justify-content-around buttons-container">
			<button (click)="activeModal.close(false)" class="btn btn-secondary d-flex justify-content-center align-items-center w-25 cancel-button">{{ "BUTTONS.CANCEL" | translate }}</button>
			<button (click)="activeModal.close(true)" class="btn btn-danger d-flex justify-content-center align-items-center w-25 confirm-button">{{ "BUTTONS.CONFIRM" | translate }}</button>
		</div>
	</div>
</div>
