import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core'
import { Util } from 'src/app/models/util.model'

import { SessionService } from 'src/app/services/session.service'
import { Patient } from 'src/app/models/patient.model'

import { Anamnesis, AnamnesisBridgeResp } from 'src/app/models/anamnesis.model'

import { Subscription } from 'rxjs'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { MessageModal, stateType } from 'src/app/elements/message-modal/message.modal'
import { TranslateService } from '@ngx-translate/core'
import { Agreeement } from 'src/app/models/agreements.model'
import { CryptoUtilsService } from 'src/app/services/crypto-utils.service'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'

@Component({
	selector: 'app-create-patient',
	templateUrl: './create-patient.component.html',
	styleUrls: ['./create-patient.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CreatePatientComponent implements OnInit, OnDestroy {
	patient: Patient
	currentStep: number
	hasImpact: boolean = false
	isLoading: boolean
	patientCreation: Subscription
	currentModal: NgbModalRef
	errorModalOpen: boolean = false
	isMobile: boolean = false

	// to handle anamnesis steps when navigating from medical to impact
	injectedSubstep: number

	// to handle mobile progression bar
	currentMobileStep: number = 1
	totalSteps: number = 4
	impactStepSize: number = 0
	medicalStepSize: number = 0

	constructor(
		public session: SessionService,
		private modalService: NgbModal,
		private translator: TranslateService,
		private cryptoUtils: CryptoUtilsService,
		private breakpointObserver: BreakpointObserver
	) {
		Util.debug('Constructor - CreatePatientComponent')
		this.isLoading = true

		this.session
			.checkUUIDValidity()
			.then(() => {
				let patient = new Patient()
				this.session.setPatient(patient)
				this.isLoading = false
			})
			.catch(() => {
				this.isLoading = false
				this.openMessage([this.translator.instant('MODAL.UUID_ERROR'), this.translator.instant('MODAL.UUID_ERROR_SUGGESTION')], stateType.WARNING)
			})
			.finally(() => (this.currentStep = 1))
	}

	ngOnInit(): void {
		// detect viewport and set isMobile accordingly
		this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((result) => {
			this.isMobile = result.matches
		})

		// subscribe, ogni volta che verrá cliccato su next, sono in ascolto e cambio pagina di conseguenza
		this.patientCreation = this.session.createPatient.subscribe((patCreationObj) => {
			this.isLoading = true
			this.session.setPatient(patCreationObj.patient.patient)

			// if step wasn't passed send patient
			if (patCreationObj.step) {
				if (patCreationObj.step === 2 && !this.patient) {
					this.initPatient()
						.then(() => {
							this.isLoading = false
							this.currentStep = 2
						})
						.catch((err) => {
							console.log(err)
							this.openMessage([this.translator.instant('MODAL.INTERNAL_ERROR')], stateType.FAIL)
						})
				} else {
					this.isLoading = false
					this.currentStep = patCreationObj.step
				}
			} else {
				// group handling
				let id = 0
				if (this.patient.group_id > 0) {
					id = this.patient.group_id
				} else {
					id = this.patient.owner_id
				}

				this.session
					.loadOpticianPKey(id)
					.then((pKey) => {
						let patientPrivData = JSON.stringify(this.patient.getPatientPrivData())

						this.cryptoUtils
							.encryptDataRSAES(patientPrivData, pKey)
							.then((resp: string) => {
								this.patient.priv_data = resp
								this.session.setPatient(this.patient)

								this.session
									.saveNewPatient()
									.then(() => {
										this.isLoading = false

										let message = this.translator.instant('MODAL.PATIENT_REGISTERED')

										this.openMessage([message], stateType.SUCCESS)

										// set currentStep to 0 to hide all "form" components
										this.currentStep = 0
									})
									.catch((err) => {
										console.log(err)
										this.openMessage([this.translator.instant('MODAL.INTERNAL_ERROR')], stateType.FAIL)
									})
							})
							.catch((err) => {
								console.log(err)
								this.openMessage([this.translator.instant('MODAL.INTERNAL_ERROR'), err], stateType.FAIL)
							})
					})
					.catch((err) => {
						console.log(err)
						this.openMessage([this.translator.instant('MODAL.INTERNAL_ERROR')], stateType.FAIL)
					})
			}
		})
	}

	private initPatient(): Promise<any> {
		return Promise.all([this.loadPatAnamnesi(), this.loadPatAgreement()]).then((resp) => {
			const medicalAnamnesis: Anamnesis[] = resp[0].medical ? resp[0].medical.questions : []
			const impactAnamnesis: Anamnesis[] = resp[0].impact ? resp[0].impact.questions : []
			this.hasImpact = impactAnamnesis && impactAnamnesis.length > 0

			let agreement: Agreeement = resp[1]

			// Sort by substep (second digit) and question number (third digit)
			medicalAnamnesis.sort((a, b) => {
				const [, substepA, questionA] = a.level.split('.').map(Number)
				const [, substepB, questionB] = b.level.split('.').map(Number)

				return substepA - substepB || questionA - questionB
			})

			impactAnamnesis.sort((a, b) => {
				const [, substepA, questionA] = a.level.split('.').map(Number)
				const [, substepB, questionB] = b.level.split('.').map(Number)

				return substepA - substepB || questionA - questionB
			})

			const medicalStepSet = new Set()
			const impactStepSet = new Set()

			medicalAnamnesis.forEach((question) => {
				const step = question.level.split('.')[1]
				medicalStepSet.add(step)
			})

			impactAnamnesis.forEach((question) => {
				const step = question.level.split('.')[1]
				impactStepSet.add(step)
			})

			this.impactStepSize = impactStepSet.size
			this.medicalStepSize = medicalStepSet.size

			this.totalSteps = this.impactStepSize + this.medicalStepSize + 2

			this.setPatient(medicalAnamnesis, impactAnamnesis, agreement)
		})
	}

	private loadPatAnamnesi(): Promise<AnamnesisBridgeResp> {
		const promise = new Promise<AnamnesisBridgeResp>((resolve, reject) => {
			this.session
				.loadPatientAnamnesi()
				.then((resp) => {
					resolve(resp)
				})
				.catch((err) => {
					console.log(err)
					reject()
				})
		})

		return promise
	}

	private loadPatAgreement(): Promise<Agreeement> {
		const promise = new Promise<Agreeement>((resolve, reject) => {
			this.session
				.loadPatientAgreemnt()
				.then((resp) => {
					resolve(resp[0])
				})
				.catch((err) => {
					console.log(err)
					reject()
				})
		})

		return promise
	}

	private setPatient(medicalAnamnesis: Anamnesis[], impactAnamnesis: Anamnesis[], agreement: Agreeement) {
		this.patient = this.session.getPatient()
		this.patient.medicalAnamnesis = medicalAnamnesis
		this.patient.impactAnamnesis = impactAnamnesis
		this.patient.agreement = agreement

		this.patient.owner_id = this.session.getpc_userId()
		this.patient.group_id = this.session.getpc_groupId()
		this.patient.uuid = this.session.getpc_uuid()

		this.patient.setUniqueHash(this.cryptoUtils)

		this.session.setPatient(this.patient)
	}

	private openMessage(text: string[], state: stateType) {
		this.currentModal = this.modalService.open(MessageModal, { size: 'l', keyboard: false, backdrop: 'static' })
		this.currentModal.componentInstance.state = state
		this.currentModal.componentInstance.texts = text

		this.errorModalOpen = state === stateType.FAIL || state === stateType.WARNING

		this.currentModal.result.then(
			(confirmed) => {
				console.log('confirmed')
			},
			(reason) => {
				console.log('reason')
				window.location.reload() // ricarico da zero la pagina
			}
		)
	}

	public goBack(step: number, comingFromMedical: boolean) {
		this.injectedSubstep = this.hasImpact && comingFromMedical ? this.impactStepSize : 1
		this.currentStep = step
	}

	nextMobileStep() {
		this.currentMobileStep++
	}

	previousMobileStep() {
		this.currentMobileStep--
	}

	ngOnDestroy(): void {
		this.patientCreation.unsubscribe()
	}
}
