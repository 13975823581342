<div>
	<div *ngIf="!isMobile" class="row">
		<div class="col-12 mb-2 substeps-container">
			<span *ngFor="let step of groupedQuestions">
				<fa-icon *ngIf="step.substep < currentStep" class="substep-icon" [icon]="faCheck"></fa-icon>
				<span class="substep-title" [ngClass]="currentStep === step.substep ? 'active' : '' ">{{step.section_title}}</span>
				<fa-icon *ngIf="step.substep !== steps" class="substep-icon" [icon]="faArrowRight"></fa-icon>
			</span>
		</div>
	</div>

	<app-mobile-header *ngIf="isMobile" [currentMainStep]="isImpact ? 2 : (hasImpact ? 3 : 2)" [currentAnamnesisStep]="currentStep" [groupedQuestions]="groupedQuestions" [ngStyle]="{'--progress-width': (currentMobileStep / totalSteps * 100) + '%', '--border-radius': '0 10px 10px 0'}" />

	<div class="p-3">
		<div class="container-fluid anamnesis-container" id="anamnesis-container">
			<div *ngIf="!isImpact" class="col-12 mb-2">
				<span>{{'CREATE_PATIENT.ANAMNESIS.SUBTITLE' | translate}}</span>
			</div>
			<div class="row mt-2" *ngFor="let questionsGroup of groupedQuestions; let i=index">
				<span *ngIf="isImpact && currentStep === i+1 && [2,3,4].includes(currentStep)" class="text-break fw-bold">{{'CREATE_PATIENT.ANAMNESIS.IMPACT_SUBTITLE' | translate}}</span>
				<div class="col-12" *ngFor="let question of questionsGroup.questions; let j=index">
					<!-- CHECKBOX / RADIO -->
					<div class="col p-1 border-bottom" [ngClass]="question.depends_on !== null && !questionDependenciesSatisfied(question) ? 'inactive' : '' " *ngIf="currentStep === i+1 && question.input_type !== 'slider'">
						<div class="d-flex mb-1" [ngClass]="isImpact && currentStep === 5 && j === 1 ? 'flex-column' : 'flex-row' ">
							<!-- check if is last question of last step of impact anamnesis -->
							<div *ngIf="isImpact && currentStep === 5 && j === 1" class="impact-last-test">
								<div class="last-test-instructions">
									<h5>{{'CREATE_PATIENT.ANAMNESIS.IMPACT_LAST_TEST_TITLE' | translate}}</h5>
									<ul>
										<li>{{'CREATE_PATIENT.ANAMNESIS.IMPACT_LAST_TEST_INSTRUCTIONS_1' | translate}}</li>
										<li>{{'CREATE_PATIENT.ANAMNESIS.IMPACT_LAST_TEST_INSTRUCTIONS_2' | translate}}</li>
										<li>{{'CREATE_PATIENT.ANAMNESIS.IMPACT_LAST_TEST_INSTRUCTIONS_3' | translate}}</li>
										<li>{{'CREATE_PATIENT.ANAMNESIS.IMPACT_LAST_TEST_INSTRUCTIONS_4' | translate}}</li>
									</ul>
								</div>
								<img class="last-test-image" [src]="getCachedImage(question.image_url)" />
							</div>

							<span class="fw-bold me-1 w-auto">{{question.question}}</span>
							<fa-icon *ngIf="question.tooltip && question.tooltip !== ''" class="w-auto" [icon]="faQuestionCircle" aria-hidden="true" [ngbTooltip]="question.tooltip" tooltipClass="question-tooltip col-4" placement="right"></fa-icon>
						</div>

						<div class="d-flex flex-wrap" [ngClass]="isMobile && question.replies.length > 2 ? 'flex-column' : ''">
							<div class="p-1 m-1 d-flex btn-answer" *ngFor="let answer of question.replies; let e=index" (click)="toggle(question, e)">
								<input class="pe-none" [type]="question.input_type" id="btn-check-{{question.question_id}}-{{answer.reply_id}}" [(ngModel)]="answer.checked" #btncheck="ngModel" name="btn-check-{{question.question_id}}-{{answer.reply_id}}" [checked]="answer.checked" autocomplete="off" />
								<label class="p-1 pe-none" for="btn-check-{{question.question_id}}-{{answer.reply_id}}">{{answer.reply}}</label>
							</div>
						</div>
					</div>

					<!-- SLIDER -->
					<div class="row p-1 border-bottom justify-content-center" [ngClass]="question.depends_on !== null && !questionDependenciesSatisfied(question) ? 'inactive' : '' " *ngIf="currentStep === i+1 && question.input_type === 'slider'">
						<div class="col">
							<div class="d-flex mb-4 align-items-center">
								<span>{{question.question}}</span>
							</div>

							<mat-slider class="slider-answer" min="0" max="4" step="1">
								<div class="slider-labels">
									<b class="text-uppercase">{{question.replies[0].reply}}</b>
									<b class="text-uppercase">{{question.replies[2].reply}}</b>
									<b class="text-uppercase">{{question.replies[4].reply}}</b>
								</div>
								<input (input)="onSliderInputChange($event, question)" matSliderThumb [value]="getSliderDefaultValue(question)" />
							</mat-slider>
						</div>
						<img class="slider-image" width="300px" [src]="getCachedImage(question.image_url)" />
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 mt-3 d-flex justify-content-between">
			<button type="button" (click)="goBack()" class="btn btn-danger">{{ "BUTTONS.BACK" | translate }}</button>
			<button type="button" (click)="currentStep === steps ? onSubmit() : goNext()" class="btn btn-danger">{{ "BUTTONS.NEXT" | translate }}</button>
		</div>
	</div>
</div>
