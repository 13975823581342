<div class="anagraphic-container" [ngClass]="show ? 'show': '' ">
	<div *ngIf="!isMobile" class="text-center my-2">
		<h4 class="panel-title fw-bold text-uppercase">{{ "CREATE_PATIENT.ANAGRAPHIC.TITLE" | translate }}</h4>

		<p>{{ "CREATE_PATIENT.ANAGRAPHIC.SUBTITLE" | translate }}</p>
	</div>

	<app-mobile-header *ngIf="isMobile" [currentMainStep]="1" [totalSteps]="totalSteps" [ngStyle]="{'--progress-width': '5%', '--border-radius': '0 10px 10px 0'}" />

	<form class="p-3" [formGroup]="form" (ngSubmit)="onSubmit()">
		<div class="row">
			<div class="form-group col-12 col-md-6 position-relative">
				<label for="firstName">{{ "CREATE_PATIENT.FIRSTNAME" | translate }}</label>
				<input type="text" class="form-control" formControlName="firstName" name="firstName" id="firstName" placeholder="{{'CREATE_PATIENT.FIRSTNAME_HINT' | translate}}" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" />
				<span class="error-message" [hidden]="!form.get('firstName').invalid">{{ "MESSAGE.REQUIRED" | translate }}</span>
			</div>

			<div class="form-group col-12 col-md-6 position-relative">
				<label for="lastName">{{ "CREATE_PATIENT.LASTNAME" | translate }}</label>
				<input type="text" class="form-control" formControlName="lastName" name="lastName" id="lastName" placeholder="{{'CREATE_PATIENT.LASTNAME_HINT' | translate}}" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" />
				<span class="error-message" [hidden]="!form.get('lastName').invalid">{{ "MESSAGE.REQUIRED" | translate }}</span>
			</div>
		</div>

		<div class="row">
			<div class="form-group col-12 col-md-6 position-relative">
				<label for="birth-date">{{"CREATE_PATIENT.BIRTH_DATE" | translate}}</label>
				<div class="position-relative">
					<input type="text" class="form-control" (click)="releasedAtPicker.open()" [matDatepicker]="releasedAtPicker" formControlName="birthDate" name="birthDate" id="birthDate" [min]="minBirthDate" [max]="maxBirthDate" readonly />
					<div class="date-picker-container">
						<mat-datepicker-toggle matSuffix [for]="releasedAtPicker"> </mat-datepicker-toggle>
						<mat-datepicker #releasedAtPicker [touchUi]="true" startView="multi-year"> </mat-datepicker>
					</div>
				</div>
				<span class="error-message" [hidden]="!form.get('birthDate').invalid">{{ "MESSAGE.REQUIRED" | translate }}</span>
			</div>

			<div class="form-group col-12 col-md-6 position-relative">
				<label for="gender">{{"CREATE_PATIENT.MODAL_GENDER" | translate}}</label>
				<select class="form-control" formControlName="gender" name="gender" id="gender" #gender [ngClass]="{'select-invalid' : gender.value == ''  }">
					<option value="" disabled selected hidden>{{'CREATE_PATIENT.MODAL_GENDER_HINT' | translate}}</option>
					<option *ngFor="let s of userGender" [ngValue]="s.code">{{s.descr | translate}}</option>
				</select>
				<span class="error-message" [hidden]="!form.get('gender').invalid">{{ "MESSAGE.REQUIRED" | translate }}</span>
			</div>
		</div>

		<div class="row">
			<div class="form-group col-12 col-md-6 position-relative">
				<label for="country">{{ "CREATE_PATIENT.COUNTRY" | translate }} </label>
				<searchdropdown *ngIf="countries != null && countries.length > 0" class="normalcase" style="padding: 0" [countries]="countries" (outcountry)="selCountry($event)" (cancelCountry)="resetCountry($event)" #country aria-required="true"> </searchdropdown>
				<span class="error-message" [hidden]="!form.get('country').invalid">{{ "MESSAGE.REQUIRED" | translate }}</span>
			</div>

			<div class="form-group col-12 col-md-6 position-relative">
				<label for="email">{{ "CREATE_PATIENT.PERSONALID" | translate }}</label>
				<input type="email" class="form-control normalcase text-uppercase" formControlName="personalid" maxlength="30" (keyup)="onPersonalIdEdit()" name="personalid" id="personalid" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" />
				<span *ngIf="!form.get('personalid').valid">
					<span class="error-message" [hidden]="!form.get('personalid').errors['required']">{{ "MESSAGE.REQUIRED" | translate }}</span>
					<span class="error-message" [hidden]="!form.get('personalid').errors['invalid tin']">{{ "MESSAGE.INVALID_TIN" | translate }}</span>
				</span>
				<div [hidden]="!checkingTin" class="info-message">
					<div class="spinner-border spinner-border-sm text-primary me-2" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<span style="font-size: 0.8rem">{{'MESSAGE.CHECHING_TIN' | translate}}</span>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="form-group col-12 col-md-6 position-relative">
				<label for="phone">{{ "CREATE_PATIENT.PHONE" | translate }}</label>

				<div class="input-group">
					<input type="text" class="input-group-text" id="phone-addon" maxlength="5" minlength="2" formControlName="countryDialCode" pattern="\+?\d*" oninput="this.value = this.value.replace(/[^0-9+]/g, '').replace(/^([0-9])(?!$)/g, '+')" autocomplete="no" />
					<input type="text" maxlength="20" class="form-control phone" formControlName="phone" name="phone" id="phone" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" oninput="this.value = this.value.replace(/[^0-9]/g, '')" />
				</div>
				<span *ngIf="!form.get('phone').valid">
					<span class="error-message" *ngIf="form.get('phone').errors['required']">{{ "MESSAGE.REQUIRED" | translate }}</span>
					<span class="error-message" *ngIf="form.get('phone').errors['No number inserted']">{{ "MESSAGE.NO_NUMBER" | translate }}</span>
					<span class="error-message" *ngIf="form.get('phone').errors['Invalid number inserted']">{{ "MESSAGE.INVALID_NUMBER" | translate }}</span>
					<span class="error-message" *ngIf="form.get('phone').errors['No country selected']">{{ "MESSAGE.NO_COUNTRY" | translate }}</span>
				</span>

				<span class="error-message" *ngIf="!form.get('countryDialCode').valid && form.get('phone').valid">{{'MESSAGE.REQUIRED_DIALCODE' | translate}}</span>
			</div>

			<div class="form-group col-12 col-md-6 position-relative">
				<label for="email">{{ "CREATE_PATIENT.EMAIL" | translate }}</label>
				<input type="email" class="form-control normalcase" formControlName="email" name="email" id="email" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" />
				<span class="error-message" [hidden]="!form.get('email').invalid">{{ "MESSAGE.EMAIL_INVALID2" | translate }}</span>
			</div>
		</div>
		<div class="row mt-3">
			<div class="col-12 text-center">
				<button type="submit" class="btn btn-danger" [disabled]="!form.valid || this.country == '' ">{{ "BUTTONS.NEXT" | translate }}</button>
			</div>
		</div>
	</form>
</div>
