<div class="row d-flex justify-content-center">
	<div class="col-lg-10 col-xl-10 col-xxl-8 col-12 position-relative p-xs-0">
		<div class="modal-container d-flex flex-column create-patient-container">
			<div class="loader-container" [ngClass]="isLoading ? 'show' : '' ">
				<div [hidden]="false" class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
			<anagraphic *ngIf="currentStep === 1" [totalSteps]="totalSteps" [isMobile]="isMobile"></anagraphic>

			<!-- IMPACT ANAMNESIS -->
			<anamnesi
				*ngIf="currentStep === 2 && hasImpact"
				[totalSteps]="totalSteps"
				[currentMobileStep]="currentMobileStep"
				[isImpact]="true"
				[hasImpact]="hasImpact"
				[injectedSubstep]="injectedSubstep"
				[anamnesisGroupId]="session.getpc_impactAnamId()"
				[isMobile]="isMobile"
				(handleGoBack)="goBack($event, false)"
				(nextMobileStep)="nextMobileStep()"
				(previousMobileStep)="previousMobileStep()"></anamnesi>

			<!-- MEDICAL ANAMNESIS -->
			<anamnesi
				*ngIf="(currentStep === 3 && hasImpact) || currentStep === 2 && !hasImpact"
				[totalSteps]="totalSteps"
				[currentMobileStep]="currentMobileStep"
				[isImpact]="false"
				[hasImpact]="hasImpact"
				[anamnesisGroupId]="session.getpc_anamId()"
				[isMobile]="isMobile"
				(handleGoBack)="goBack($event, true)"
				(nextMobileStep)="nextMobileStep()"
				(previousMobileStep)="previousMobileStep()"></anamnesi>

			<agreement *ngIf="(currentStep === 3 && !hasImpact) || currentStep === 4" [totalSteps]="totalSteps" [isMobile]="isMobile"></agreement>
		</div>
	</div>
</div>
