<div class="agreement-container" [ngClass]="show ? 'show': '' ">
	<div>
		<div *ngIf="!isMobile">
			<h4 class="panel-title fw-bold text-uppercase text-center">{{ "CREATE_PATIENT.AGREEMENT.TITLE" | translate }}</h4>

			<p>{{ "CREATE_PATIENT.AGREEMENT.SUBTITLE" | translate }}</p>
		</div>

		<app-mobile-header *ngIf="isMobile" [currentMainStep]="4" [totalSteps]="totalSteps" [ngStyle]="{'--progress-width': '100%', '--border-radius': '0'}" />

		<div class="p-3">
			<div class="row mt-5">
				<div class="col-12 d-flex align-items-center">
					<div class="form-check form-switch">
						<input class="form-check-input form-control" type="checkbox" name="flexSwitchCheckDefault" id="flexSwitchCheckDefault" [(ngModel)]="patient.agreementAccepted" />
					</div>
					<label class="form-check-label ms-2">
						{{ "CREATE_USER.TERMS" | translate }}

						<a (click)="getTerms()" class="link-dark" style="cursor: pointer"> {{ "CREATE_USER.TERMS_4" | translate }} </a>
					</label>
				</div>
			</div>

			<div class="row mt-5">
				<div class="col-12">
					<p>{{ "CREATE_PATIENT.AGREEMENT.OTP_TITLE" | translate }}</p>
					<div class="row">
						<div class="position-relative col-12 col-md-6">
							<div class="input-group">
								<!-- <span class="input-group-text" id="phone-addon" *ngIf="patient.country != '' ">{{patient.countryObj.dialCode}}</span> -->
								<input
									type="text"
									class="input-group-text"
									name="dialCode"
									id="dialCode"
									maxlength="5"
									minlength="2"
									#countrydialCode="ngModel"
									[(ngModel)]="dialCode"
									[disabled]="!editNumber"
									pattern="\+?\d*"
									oninput="this.value = this.value.replace(/[^0-9+]/g, '').replace(/^([0-9])(?!$)/g, '+')"
									required />
								<input type="text" name="phone" id="phone" maxlength="20" class="form-control phone" aria-describedby="phone-addon1" #phonenumber="ngModel" [(ngModel)]="phone" [disabled]="!editNumber" (keyup)="validateOtpPhone()" oninput="this.value = this.value.replace(/[^0-9]/g, '')" required />
							</div>

							<fa-icon [icon]="faPen" faPen (click)="editNumber = !editNumber" [ngClass]="editNumber ? 'hide': '' "></fa-icon>

							<span class="error-message" *ngIf="!countrydialCode.valid && phonenumber.valid">{{'MESSAGE.REQUIRED_DIALCODE' | translate}}</span>
							<span class="error-message" *ngIf="!phonenumber.valid && phonenumber.enabled">{{ "MESSAGE.REQUIRED" | translate }}</span>
							<span class="error-message" *ngIf="otpPhoneError && phonenumber.valid">{{ "MESSAGE.INVALID_NUMBER" | translate }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="row mt-5">
				<div class="form-group col-12 col-md-6 button-container">
					<button type="button" (click)="onSubmit(); editNumber=false" class="btn btn-danger d-flex justify-content-center align-items-center" [disabled]="!patient.agreementAccepted || otpPhoneError || countrydialCode.invalid">
						<span *ngIf="!otpServiceLoading">{{ "BUTTONS.SEND_OTP_CODE" | translate }}</span>
						<div class="spinner-border spinner-border-sm" role="status" *ngIf="otpServiceLoading">
							<span class="sr-only">{{ "CREATE_PATIENT.AGREEMENT.OTP_LOADING" | translate }}</span>
						</div>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
