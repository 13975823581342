import { Component, Input, AfterViewInit, OnInit, EventEmitter, Output } from '@angular/core'
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms'
import { Anamnesis, PatientReply } from 'src/app/models/anamnesis.model'
import { Patient, PatientObj } from 'src/app/models/patient.model'

import { SessionService } from 'src/app/services/session.service'

import { faXmark, faCheck, faArrowRight, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { Util } from 'src/app/models/util.model'
import { TranslateService } from '@ngx-translate/core'
import { SafeUrl, DomSanitizer } from '@angular/platform-browser'

export interface GroupedQuestions {
	substep: number
	questions: Anamnesis[]
	section_title: string
}

interface QuestionDependency {
	q: string[]
	r: {
		[key: string]: number[]
	}
}

@Component({
	selector: 'anamnesi',
	templateUrl: './anamnesi.component.html',
	styleUrls: ['./anamnesi.component.scss'],
})
export class AnamnesiComponent implements AfterViewInit, OnInit {
	@Input() tabId: number
	@Input() isMobile: boolean
	@Input() anamnesisGroupId: number
	@Input() isImpact: boolean
	@Input() hasImpact: boolean
	@Input() currentMobileStep: number = 1
	@Input() totalSteps: number
	@Input() injectedSubstep: number

	@Output() handleGoBack = new EventEmitter<number>()
	@Output() nextMobileStep = new EventEmitter()
	@Output() previousMobileStep = new EventEmitter()

	patient: Patient
	show: boolean
	formValid: boolean

	groupedQuestions: GroupedQuestions[]
	steps: number
	currentStep: number

	imageCache: Map<string, SafeUrl> = new Map()

	faCheck = faCheck
	faArrowRight = faArrowRight
	faQuestionCircle = faQuestionCircle

	// anamnesiform: FormGroup

	constructor(public session: SessionService, public translator: TranslateService, private sanitizer: DomSanitizer) {
		Util.debug('Constructor - AnamnesiComponent')

		this.patient = this.session.getPatient()
		this.formValid = false
		this.show = true
	}

	ngOnInit(): void {
		this.currentStep = this.injectedSubstep || 1

		this.groupedQuestions = Object.values(
			(this.isImpact ? this.patient.impactAnamnesis : this.patient.medicalAnamnesis).reduce((acc, question) => {
				const levelParts = question.level.split('.')
				const substep = levelParts[1] // Get the substep (second number of level)

				if (question.image_url && !this.imageCache.has(question.image_url)) {
					this.preloadImage(question.image_url)
				}

				if (!acc[substep]) {
					acc[substep] = {
						substep: parseInt(substep),
						questions: [],
						section_title: question.section_title,
					}
				}

				if (levelParts[2] !== '0') {
					acc[substep].questions.push(question)
				}

				return acc
			}, {} as { [key: string]: GroupedQuestions })
		)

		this.steps = this.groupedQuestions.length
		// this.steps = this.groupedQuestions.map((el) => this.translator.instant(`PROGRESS_HEADER.TITLE_${parseInt(el.substep)}_GROUP_${this.anamnesisGroupId}`))
	}

	ngAfterViewInit(): void {
		window.scrollTo(0, 0)
	}

	// getControls() {
	// 	return (<FormArray>this.anamnesiform.get('questions')).controls
	// }

	toggle(obj: Anamnesis, n: number) {
		// console.log(obj)
		let replies = obj.replies

		if (obj.input_type === 'radio') {
			for (let i = 0; i < replies.length; i++) {
				replies[i].checked = false
			}
		}

		//imposto true solo quella selezionata
		replies[n].checked = !replies[n].checked
	}

	onSliderInputChange(event: Event, question: Anamnesis) {
		const val = (event.target as HTMLInputElement).value
		let replies = question.replies

		for (let i = 0; i < replies.length; i++) {
			replies[i].checked = false
		}

		//imposto true solo quella selezionata
		replies[val].checked = !replies[val].checked
	}

	getSliderDefaultValue(question: Anamnesis): number {
		const checkedAnswerIndex = question.replies.findIndex((answer) => answer.checked)
		return checkedAnswerIndex >= 0 ? checkedAnswerIndex : 0 // Fallback to 0 if none is checked
	}

	questionDependenciesSatisfied(question: Anamnesis): boolean {
		try {
			const dependency: QuestionDependency = JSON.parse(question.depends_on)

			let flag = true

			dependency.q.forEach((depQuestionLevel) => {
				const depQuestion = (this.isImpact ? this.patient.impactAnamnesis : this.patient.medicalAnamnesis).find(
					(anamQuestion) => anamQuestion.level === depQuestionLevel
				)

				if (depQuestion) {
					// Check if any of the specified replies are checked
					const isAnyChecked = dependency.r[depQuestionLevel].some((replyIndex) => {
						const replyId = depQuestion.replies[replyIndex - 1].reply_id
						const replyElement = document.getElementById(`btn-check-${depQuestion.question_id}-${replyId}`) as HTMLInputElement
						return replyElement && replyElement.checked
					})

					if (!isAnyChecked) {
						flag = false
					}
				}
			})

			// If dependencies are not satisfied, uncheck all inputs of the current question
			if (!flag) {
				question.replies.forEach((answer) => {
					const replyElement = document.getElementById(`btn-check-${question.question_id}-${answer.reply_id}`) as HTMLInputElement
					if (replyElement) {
						answer.checked = false
						replyElement.checked = false
					}
				})
			}

			return flag
		} catch (error) {
			console.log('There was an error checking the dependency question(s):', error)
		}
	}

	preloadImage(url: string): void {
		fetch(url)
			.then((response) => response.blob())
			.then((blob) => {
				const safeUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))
				this.imageCache.set(url, safeUrl)
			})
			.catch((error) => console.error('Image preload error:', error))
	}

	getCachedImage(url: string): SafeUrl | null {
		return this.imageCache.get(url) || null
	}

	onSubmit() {
		const patientReply: PatientReply[] = []
		this.groupedQuestions.forEach((group) => {
			group.questions.forEach((question) => {
				const replyCheckedIds = question.replies
					.filter((reply) => reply.checked)
					.map((reply) => {
						return reply.reply_id
					})
				patientReply.push({
					question_id: question.question_id,
					reply_ids: replyCheckedIds,
				})
			})
		})

		if (this.isImpact) {
			this.patient.impactReply = patientReply
		} else {
			this.patient.medicalReply = patientReply
		}

		let patObj: PatientObj = new PatientObj(this.patient)
		patObj.id = this.tabId

		this.show = false

		this.nextMobileStep.emit()
		// if on medical && impact was previously filled, go to step 4, else step 3 (it will always be the agreements tab)
		this.session.createPatient.next({ patient: patObj, step: !this.isImpact && this.hasImpact ? 4 : 3 })
	}

	goBack() {
		const anamnesisHtmlElement = document.getElementById('anamnesis-container')
		anamnesisHtmlElement.scrollTo({ top: 0, behavior: 'smooth' })
		// Determine step to navigate based on if we are on impact tab: if on medical, check if impact anamnesis tab is available first
		const stepToNavigate = this.isImpact ? 1 : this.hasImpact ? 2 : 1

		// Adjust current step or emit go-back event
		if (this.currentStep > 1) {
			this.previousMobileStep.emit()
			this.currentStep--
		} else {
			// only if not going back to anagraphic
			if (stepToNavigate !== 1) {
				this.previousMobileStep.emit()
			}
			this.handleGoBack.emit(stepToNavigate)
		}
	}

	goNext() {
		// const anamnesisHtmlElement = document.getElementById('top')
		window.scrollTo({ top: 0, behavior: 'smooth' })

		this.nextMobileStep.emit()
		this.currentStep++
	}
}
