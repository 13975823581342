import { Component } from '@angular/core'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'opt-confirm-number-modal',
	templateUrl: './opt-confirm-number.modal.html',
	styleUrls: ['./opt-confirm-number.modal.scss'],
	providers: [NgbModal],
})
export class OtpConfirmNumberModal {
	formattedOtpPhone: string

	constructor(public activeModal: NgbActiveModal) {}
}
