import { NgModule, Injector, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { createCustomElement } from '@angular/elements'
import { BrowserModule } from '@angular/platform-browser'
import { DatePipe } from '@angular/common'

import { NgbModule, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { JwtModule } from '@auth0/angular-jwt'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { InternationalizationModule } from './internationalization/internationalization.module'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateLoader, TranslateModule, TranslateStore } from '@ngx-translate/core'

//material
import { MatInputModule } from '@angular/material/input'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core'
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MatSliderModule } from '@angular/material/slider'

//modules
import { CdTimerModule } from 'angular-cd-timer'
import { MessageModal } from './elements/message-modal/message.modal'
import { OtpModal } from './elements/otp-modal/otp.modal'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LoginComponent } from './component/login/login.component'
import { CreateUserComponent } from './component/user/user.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HeaderComponent } from './component/header/header.component'
import { SearchdropdownComponent } from './elements/searchdropdown/searchdropdown.element'
import { QrcodeComponent } from './component/qrcode/qrcode.component'
import { ProfileComponent } from './component/profile/profile.component'
import { LoginformComponent } from './elements/loginform/loginform.component'
import { NgOtpInputModule } from 'ng-otp-input'
import { CreatePatientComponent } from './component/create-patient/create-patient.component'
import { AnagraphicComponent } from './component/create-patient/anagraphic/anagraphic.component'
import { AnamnesiComponent } from './component/create-patient/anamnesi/anamnesi.component'
import { AgreementComponent } from './component/create-patient/agreement/agreement.component'
import { MobileHeaderComponent } from './component/create-patient/mobile-header/mobile-header.component'
import { OtpConfirmNumberModal } from './elements/opt-confirm-number-modal/opt-confirm-number.modal'

/**
 * The http loader factory : Loads the files from define path.
 * @param {HttpClient} http
 * @returns {TranslateHttpLoader}
 * @constructor
 */
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '../assets/locales/', '.json')
}

export function tokenGetter() {
	return localStorage.getItem('access_token')
}

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		HeaderComponent,
		CreateUserComponent,
		SearchdropdownComponent,
		QrcodeComponent,
		LoginformComponent,
		ProfileComponent,
		CreatePatientComponent,
		MessageModal,
		OtpModal,
		OtpConfirmNumberModal,
		AnagraphicComponent,
		AnamnesiComponent,
		AgreementComponent,
		MobileHeaderComponent,
	],
	imports: [
		CdTimerModule,
		BrowserModule,
		NgbModule,
		HttpClientModule,
		FormsModule,
		FontAwesomeModule,
		ReactiveFormsModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		NgbModule,
		NgOtpInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatInputModule,
		MatRippleModule,
		MatSliderModule,
		InternationalizationModule.forRoot({ locale_id: 'en_EN' }),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),

		JwtModule.forRoot({
			config: {
				tokenGetter: tokenGetter,
				allowedDomains: [''],
				disallowedRoutes: [''],
			},
		}),
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [
		DatePipe,
		NgbModal,
		TranslateStore,
		MatDatepickerModule,
		// // these providers are necessary for material datepicker
		{ provide: MAT_DATE_LOCALE, useValue: 'en_EN' },
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private injector: Injector) {}
	ngDoBootstrap() {
		const dd = createCustomElement(SearchdropdownComponent, {
			injector: this.injector,
		})
		// customElements.define('searchdropdown', dd);
		// const dc = createCustomElement(BotcheckComponent, {
		//   injector: this.injector,
		// });
		// customElements.define('BotcheckComponent', dc);
	}
}
