import { Component, Input, OnInit } from '@angular/core'
import { GroupedQuestions } from '../anamnesi/anamnesi.component'

@Component({
	selector: 'app-mobile-header',
	templateUrl: './mobile-header.component.html',
	styleUrl: './mobile-header.component.scss',
})
export class MobileHeaderComponent {
	@Input() currentMainStep: number
	@Input() currentAnamnesisStep: number
	@Input() groupedQuestions: GroupedQuestions[]

	getHeaderTitle(): string {
		if (this.currentMainStep === 1) {
			return 'PROGRESS_HEADER.TITLE_DETAILS'
		} else if (this.currentMainStep === 4) {
			return 'PROGRESS_HEADER.TITLE_ACKNOWLEDGMENT'
		} else {
			return this.groupedQuestions.find((q) => q.substep === this.currentAnamnesisStep).section_title
		}
	}
}
